export const config = {
    RPC_URL: 'https://rpc-cerberus-ia.cosmosia.notional.ventures',
    REST_URL: 'https://api-cerberus-ia.cosmosia.notional.ventures',
    EXPLORER_URL: 'https://www.mintscan.io/cerberus',
    STAKING_URL: 'https://cerberus.omniflix.co/stake',
    NETWORK_NAME: 'Cerberus',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'cerberus-chain-1',
    CHAIN_NAME: 'Cerberus Zone',
    COIN_DENOM: 'CRBRUS',
    COIN_MINIMAL_DENOM: 'ucrbrus',
    COIN_DECIMALS: 6,
    PREFIX: 'cerberus',
    COIN_TYPE: 118,
    COSMOSTAION: 'cerberus',
    COINGECKO_ID: 'cerberus-2',
    GAS_PRICE_STEP_LOW: 0.0025,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
